.body {
  min-height: 100vh;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  background: #ffffff;
  border-radius: 30px;
  padding: 1rem;
  margin: 2rem;
  height: calc(100% - 19rem);
  flex-direction: column;
  gap: 2rem;
  /* width: 50%; */
  /* background-color: rgba(255, 255, 255, 0.95); */
  overflow-y: auto;
}

.side {
  flex: 1;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}
.backdrop {
  /* position: fixed; */
  background: rgba(255, 242, 238, 0.94);
  height: 100%;
  width: 100%;
   padding-left: 10%;
 padding-right: 10%;
  min-height: 100vh;
}
@media screen and (max-width:786px) {
  .backdrop {
 background: rgba(255, 242, 238, 0.94);
  height: 100%;
  width: 100%;
     padding-left: 1%;
 padding-right: 1%;
  min-height: 100vh;

}
}


.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
	max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: #101828;
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: #d32028;
}
.headerLabelText {
  text-align: start;
  color: #101828;
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: #fff8f4;
  border: 1px solid rgba(212, 33, 41, 0.15);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: #d32028;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.content {
  padding: 2rem;
  background: #fff8f4;
  border: 1px solid rgba(212, 33, 41, 0.15);
  border-radius: 30px;
}


.addressInputGrid {
  /* display: grid; */
  /* direction: ltr; */
  /* grid-template-areas:
    "door door street street"
    "city state pin save"; */
  /* gap: 1rem; */
  display: flex;
  gap: 2.5rem;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:786px) {
  .addressInputGrid {
  /* display: grid; */
  /* direction: ltr; */
  /* grid-template-areas:
    "door door street street"
    "city state pin save"; */
  /* gap: 1rem; */
  display: block;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 74vw;

}
}

.employmentDetialsActive {
  background: #ffffff;
  color: #d32028;
  padding: 0.4rem ;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  text-align: center;
  margin: 1.2em;
  border: 1px solid #f9d8d6;
  cursor: pointer;
  width: 12em;
}

.employmentDetialsInactive {
  background: #fff8f4;
  color: #344054;
  padding: 0.4rem ;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 6px;
  text-align: center;
  margin: 1.2em;
  border: 1px solid #f9d8d6;
  cursor: pointer;
  width: 12em;
}

.divider {
  border-left: 1px solid rgba(212, 33, 41, 0.15);
  height: 50px;
}
