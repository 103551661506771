.body {
  height:100%;
  width: 100%;
  background: url("../../images/hero_bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-content: center;
  justify-content: center;
  
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 30px;
  padding-top: 1rem;
  padding: 1rem;
  
  height: auto;
  flex-direction: column;
 

  /* background-color: rgba(255, 255, 255, 0.95); */
}

.side {
  flex: 1;
  background: url("../../images/hero_bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}
 
.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
  max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: #101828;
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: #d32028;
}
.headerLabelText {
  text-align: start;
  color: #101828;
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: #fff8f4;
  border: 1px solid rgba(212, 33, 41, 0.15);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: #d32028;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.content {
  padding: 2rem;
  margin: 1rem;
  background: #fff8f4;
  border: 1px solid rgba(212, 33, 41, 0.15);
  border-radius: 30px;
  width: 80%;
  
 
}
