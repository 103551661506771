.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.helpCenter {
  color: #d32028;
  font-size: 1.2em;
  padding: 0.5rem 0;
}

.contactDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-left:1em ;
  margin-right: 1em;
  font-size: 1.3rem;
}

.contactItem {
  display: flex;
  gap: 0.4rem;
  align-self: center;
  justify-content: center;
}
@media screen and (max-width:786px){
  

  .contactDiv {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-left:1em ;
  margin-right: 1em;
  font-size: 1.3rem;
}
}

.copyrightText {
  /* padding: 2rem 0 1rem 0; */
  font-size: 1em;
  
  text-align: center;
}
