.body {
  height:100%;
  width: 100%;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
 
}

.container {
  
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  background: #ffffff;
  border-radius: 30px;
  padding: 1rem;
  margin: 2rem ;
  height: calc(100% - 19rem);
  flex-direction: column;
  gap: 2rem;
  /* width: 80%; */
  /* background-color: rgba(255, 255, 255, 0.95); */
  overflow-y: auto;

}


.side {
  flex: 1;
  background: url("../../images/hero_bg.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
}
.backdrop {
  /* position: fixed; */
  background: rgba(255, 242, 238, 0.94);
  height: 100%;
  width: 100%;
   padding-left: 10%;
   padding-right: 10%;
  min-height: 100vh;
}
@media screen and (max-width:786px) {
  .backdrop {
   background: rgba(255, 242, 238, 0.94);
  height: 100%;
  width: 100%;
   padding-left: 1%;
   padding-right: 1%;
  min-height: 100vh;

}
}
.sideContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 8rem);
  padding: 4rem 8rem;
}
.main {
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* min-width: 40rem;
	max-width: 90vh; */
}
.headerText {
  text-align: center;
  color: #101828;
  font-size: 1.5rem;
}
.headerNextText {
  text-align: center;
  color: #d32028;
}
.headerLabelText {
  text-align: start;
  color: #101828;
  font-size: 1.3rem;
  margin-top: 1rem;
}
.nextStepsCard {
  background: #fff8f4;
  border: 1px solid rgba(212, 33, 41, 0.15);
  border-radius: 12px;
  padding: 2rem 1rem;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 3;
}
.logoContainer p {
  font-size: 2rem;
  text-align: center;
  padding-top: 1rem;
}
.footer {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: end;
}
.mainContainer {
  padding: 8rem;
}
.secondaryImage {
  position: fixed;
  bottom: 0;
  right: 0;
}

.textTitle {
  color: #d32028;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 1rem;
}

.textDesc {
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
}
.content {
  padding: 2rem;
  background: #fff8f4;
  border: 1px solid rgba(212, 33, 41, 0.15);
  border-radius: 30px;
}
.addressInputGrid {
  display: grid;
  grid-template-areas:
    "door door door door"
    "city state pin save";
  gap: 1rem;
}
