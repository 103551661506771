*{
    font-family: 'Inter';
  }
  

  @media screen  and (min-width: 300px) and (max-width: 576px) {
    .login-form-container{
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
      align-items: center;
     background-color: #FFFFFF;
  max-height: 100vh;
     height: 100%;
     width: 100%;
     background-repeat: no-repeat;
     overflow:hidden;
   
  } 
    .login-background-mobile{
      width: 100%;
      background-color: #FFF2EE;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      text-align: center;
      align-items: center;
      height: 13vh;
       
      }
      .login-image{
        width: 100%;
        height: 100%;
        opacity: 0.1;
        display: none;
        
    }
    .feemonk-image-mobile {
      
        height: 60%;
        width: 50%;
      }
      .applybtn1-mobile{
   
        border: none;
        background-color: #D32028;
        color: white;
        font-family: 'Inter-Medium';
        border-radius: 5px;
        font-size: 3vw;
        padding: 1.5%;
  
      }
      .applybtn1{
        display: none;
      }

      .feemonk-image{
        display: none;
      }

      .caption{
        display: none;
      }

        .login{

       display: none;
    }
    .login-mobile{
      color:#2c6ce3 ;
      font-family: 'Inter-Medium';
      font-size: 25px;
  
     margin-left: 10%;
  }

  .otp-resend{
    display: none;
  }
  .otp-resend-mobile{
    font-weight: bold;
    color:#667085;
    margin-left: 10%;
    font-family: 'Inter-Medium';
    font-size: 11px;
  }
    .form-container{
      display: none;
    }
    .form-container-mobile{
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-height: 100vh;
      height: 100%;
      width: 100%;
      margin-left: 10%;
      margin-top: 10%;
      margin-bottom: 10%;


  }
    .email-input-filed {
      display: none;

      }
      .email-input-filed-mobile {
        font-size: 14px;
        height: 40px;
        border: 1px solid #d7dfe9;
        background-color: #FFFFFF;
        color: #64748b;
        border-radius: 12px;
        margin-top: 10px;
        padding: 8px 16px 8px 16px;
      margin-left: 10%;
        width: 70%;
      }
      .password-input-filed {

        display: none;
      }
      .started-mobile{
        margin-left: 10%;
        margin-top: 5%;
        font-family: 'Inter-Medium';
        color:#667085;
      }
      .password-input-filed-mobile {
        font-size: 14px;
        height: 40px;
        border: 1px solid #d7dfe9;
        background-color: #FFFFFF;
        color: #64748b;
        border-radius: 12px;
        margin-left: 10%;
        margin-top: 5px;
        width:  70%;
        padding: 8px 16px 8px 16px;
      }
      .login-button {
       
       display: none;
        
      }
      .login-button-mobile {
        color: #FFFFFF;
        height: 40px;
        margin-left: 10%;
        margin-top: 20px;
        margin-bottom: 2px;
        margin-right: 16px;
        background-color:#2c6ce3;
        border-radius: 16px;
        border: none;
        font-family:'Inter-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        width: 70%;
       
        
      }
      .login-button1 {

        display: none;
      }
      .login-button1-mobile {
        color: #FFFFFF;
        height: 40px;
        margin-left: 10%;
        margin-bottom: 2px;
        margin-right: 16px;
        background-color:#D32028;
        border-radius: 16px;
        border: none;
        font-family:'Inter-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        width: 70%;
        margin-top: 10%;
      }

      .or{
  
        display: none;
      }
      .or-mobile{
        margin-left: 40%;
        margin-top: 10%;
        color: #667085;
        line-height: 10px;
        font-family: 'Inter-Medium';
        
      }

  }

  @media screen  and (min-width: 577px) and (max-width: 768px) {
    .login-form-container{
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
      align-items: center;
     background-color: #FFFFFF;
  max-height: 100vh;
     height: 100%;
     width: 100%;
     background-repeat: no-repeat;
     overflow:hidden;
  
  
  } 
    .login-background-mobile{
      width: 100%;
      background-color: #FFF2EE;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      text-align: center;
      align-items: center;
       
      }
      .login-image{
        width: 100%;
        height: 100%;
        opacity: 0.1;
        display: none;
        
    }
    .feemonk-image-mobile {
      
        height: 80%;
        width: 50%;
      }
      .applybtn1-mobile{
   
        border: none;
        background-color: #D32028;
        color: white;
        font-family: 'Inter-Medium';
        border-radius: 5px;
        font-size: 18px;
        padding: 10px;
  
      }
      .applybtn1{
        display: none;
      }

      .feemonk-image{
        display: none;
      }

      .caption{
        display: none;
      }

        .login{

       display: none;
    }
    .login-mobile{
      color:#3169ec ;
      font-family: 'Inter-Medium';
      font-size: 25px;
  
     margin-left: 10%;
  }
    .edit{
    
      display: none;
  }
  .otp-resend{
    display: none;
  }
  .otp-resend-mobile{
    font-weight: bold;
    color:#667085;
    margin-left: 10%;
    font-family: 'Inter-Medium';
    font-size: 11px;
  }

    .form-container{
      display: none;
    }
    .form-container-mobile{
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;

padding-left: 10%;
  }
    .email-input-filed {
      display: none;

      }
      .email-input-filed-mobile {
        font-size: 14px;
        height: 40px;
        border: 1px solid #d7dfe9;
        background-color: #FFFFFF;
        color: #64748b;
        border-radius: 12px;
        margin-top: 10px;
        padding: 8px 16px 8px 16px;
      margin-left: 10%;
        width: 70%;
      }
      .password-input-filed {

        display: none;
      }
      .started-mobile{
        margin-left: 10%;
        margin-top: 5%;
        font-family: 'Inter-Medium';
        color:#667085;
      }
      .password-input-filed-mobile {
        font-size: 14px;
        height: 40px;
        border: 1px solid #d7dfe9;
        background-color: #FFFFFF;
        color: #64748b;
        border-radius: 12px;
        margin-left: 10%;
        margin-top: 5px;
        width:  70%;
        padding: 8px 16px 8px 16px;
      }
      .login-button {
       
       display: none;
        
      }
      .login-button-mobile {
        color: #FFFFFF;
        height: 40px;
        margin-left: 10%;
        margin-top: 20px;
        margin-bottom: 2px;
        margin-right: 16px;
        background-color:#D32028;
        border-radius: 16px;
        border: none;
        font-family:'Inter-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        width: 70%;
        
      }
      .login-button1 {

        display: none;
      }
      .login-button1-mobile {
        color: #FFFFFF;
        height: 40px;
        margin-left: 10%;
        margin-bottom: 2px;
        margin-right: 16px;
        background-color:#D32028;
        border-radius: 16px;
        border: none;
        font-family:'Inter-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        width: 70%;
      margin-bottom: 10%;
        margin-top: 10%;
      }

      .or{
  
        display: none;
      }
      .or-mobile{
        margin-left: 40%;
       
        color: #667085;
        line-height: 10px;
        font-family: 'Inter-Medium';
        margin-top: 10%;
      }

  }
  @media screen  and (min-width: 769px) {
    .login-background-mobile{
      display: none;
    }
    .form-container-mobile{
      display: none;
    }
    .otp-resend{
      font-weight: bold;
      color:#667085;
      margin-left: 20%;
      font-family: 'Inter-Medium';
      font-size: 11px;
    }
  
    .started{
      color: #667085;
      font-family: 'Inter-Medium';
      margin-left: 20%;
    }
    .login-form-container{
        display: flex;
        flex-direction: row;
        /* justify-content: space-around; */
        align-items: center;
       background-color: #FFFFFF;
      max-height: 100vh;
       height: 100%;
       width: 100%;
       background-repeat: no-repeat;
       overflow:hidden;
       
    
         
    } 
    .login-background{
      background-color: #d7dfe9;
      /* background-image: url("../../assets/images/Vector1.png"); */
      background-repeat: no-repeat;
      width: 50%;
     height: 100vh;
      position: relative;
      box-shadow: 0px 8px 40px rgba(5, 5, 5, 0.38);
      border-radius: 18px;
    
     
    }
    
    .login-background-2{
      /* background-image: url("../../assets/images/Group_1.png"); */
      background-repeat: no-repeat;
      /* background-size: 70% 100%; */
    /* left:-30px; */
    
    }  
    
    .feemonk-image {
    
      position: absolute;
      top: 30%;
      left: 25%;
      height: 7.5vw;
      width: 25vw;
    }
    
    .caption{
    
      height: 50%;
      width: 50%;
      position: absolute;
      top: 55%;
      left: 25%;
      font-family:'Inter';
      font-style: normal;
      font-weight: 200;
      font-size: 24px;
      line-height: 36px;
      /* or 120% */
    
      text-align: center;
    
      color: #000000;
    
    }
    
    
    
    
    .login-image{
        width: 100%;
        height: 100%;
        opacity: 0.1;
        position: relative;
        top: 0;
        left: 0;
    }
    
    .form-container{
        display: flex;
        flex-direction: column;
      
        height: 100%;
        width: 40%;

    
    
    }
    .registered{
      font-family: 'Inter-Medium';
      color:#667085;
   margin-left: auto;
    }
    .input-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        width: 100%;
      }
    
      .input-label {
      
        font-family:'Inter-Medium';
        color: #3B3A42;
        margin-left: 60px;
       
      }
      
      .error{
          color: red;
          margin-left: 60px;
          font-family: 'Inter-Medium';
          font-size: '12px';
      }
      .email-input-filed {
        font-size: 14px;
        height: 40px;
        border: 1px solid #d7dfe9;
        background-color: #FFFFFF;
        color: #64748b;
        border-radius: 12px;
        margin-top: 10px;
        padding: 8px 16px 8px 16px;
        margin-left: 20%;
        width: 45%;
      }
      
      .password-input-filed {
        font-size: 14px;
        height: 40px;
        border: 1px solid #d7dfe9;
        background-color: #FFFFFF;
        color: #64748b;
        border-radius: 12px;
        margin-top: 5px;
        width: 45%;
        padding: 8px 16px 8px 16px;
        margin-left: 20%;
      }
    
      .buttons-container1{
        /* display:flex;
        flex-direction: column;
        justify-content: space-around; */
  
        padding-top: 25px;
        
      }
      
      .login{
          color:#2c6ce3 ;
          font-family: 'Inter-Medium';
          font-weight: 'bold';
          margin-left: 20%;
      }
      .applybtn1{
        background-color: #D32028;
        margin-top: 5%;
        border: none;
        font-family: 'Inter-Medium';
        color: white;
        font-size: 25px;
        padding: 2%;
        border-radius: 5px;
      }
  
  
      .login-button {
        color: #FFFFFF;
        height: 40px;
        
        margin-top: 20px;
        margin-bottom: 2px;
        margin-right: 16px;
        background-color:#2c6ce3;
        border-radius: 16px;
        border: none;
        font-family:'Inter-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        width: 45%;
        margin-left:  20%;
        
      }
      .login-button1 {
        color: #FFFFFF;
        height: 40px;

        margin-bottom: 2px;
        margin-right: 16px;
        background-color:#D32028;
        border-radius: 16px;
        border: none;
        font-family:'Inter-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        width: 75%;
        margin-left:  20%;
        
      }
      .or{
        margin-left: 55%;
        margin-top: 10px;
        color: #ccc;
        line-height: 10px;
      }
      .error-message{
        font-size: 14px;
        height: 40px;
        color: red;
        font-family:'Inter';
        margin-top: 5px;
        padding: 8px 16px 8px 16px;
      }
    
      
  .email-input-filed::placeholder {
      color: #999;
      font-style: 'Inter-Medium'; 
    }
  }
  .rnc {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 255px;
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 10px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 10px;
  }
  
  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .rnc-button svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }