.inputField input,
.inputField select,
.inputField textarea {
  background: #fff;
  border: 1px solid #6f6f6f;
  /* border: 1px solid #d0d5dd; */
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 1em;
  font-weight: 500;
  font-size: 1em;
  font-family: "Outfit-Medium";
  /* margin: 10px 0; */

  margin: 5px;
  resize: vertical;
}
.inputField input:focus,
.inputField select:focus,
.inputField textarea:focus {
  border: 1px solid #6f6f6f;
}
.toggleSwitch {
  margin: 10px;
}
.switchInput {
  margin: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switchInput:checked + .slider {
  background: linear-gradient(90deg, #ff6648 0%, #dd2476 100%);
}
.switchInput:focus + .slider {
  box-shadow: 0 0 1px #ff6648;
}
.switchInput:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.inputFieldSquare input,
.inputFieldSquare select,
.inputFieldSquare textarea {
  border-radius: 0.5rem !important;
}
