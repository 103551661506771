

.table-of-contents1 {
    width: 100%;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;


  }
  
  .table-of-contents1 th,
  .table-of-contents1 td {
    border: 1px solid #ddd;
    padding: 10px;
    
  }
  
 
  .table-of-contents1.mobile th,
  .table-of-contents1.mobile td {
    font-family: 'Outfit-Medium';
    font-size: 12px; 
  }

  .table-of-contents1.desktop th,
  .table-of-contents1.desktop td {
    font-family: 'Outfit-Medium';
    font-size: 14px; 
  }
  
